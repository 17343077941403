import Link from "next/link";

const SolutionItem = ({ title, description, variant }) => {
  return (
    <div className="lg:w-1/3 w-1/2 p-2">
      <div
        className={`lg:p-4 flex flex-col gap-1 h-[200px] items-center ${
          variant == "odd"
            ? "bg-[#0F7996] bg-opacity-[4%]"
            : "bg-[#FC7F3A] bg-opacity-[3%]"
        } rounded-[10px]`}
      >
        <p className="text-primary lg:text-[30px] text-[18px] font-bold">
          {title}
        </p>
        <p className="text-center lg:text-[20px] text-[14px]">{description}</p>
      </div>
    </div>
  );
};

export const Solution = () => {
  const solutionData = [
    {
      title: "Akademik",
      description:
        "Mengelola jadwal mata pelajaran, presensi harian dan presensi mata pelajaran, presensi kegiatan, serta nilai hingga rapor digital siswa.",
    },
    {
      title: "Keuangan",
      description:
        "Pengelolaan program tabungan siswa dan program donasi, serta pembukuan dan laporan pembayaran tagihan otomatis.",
    },
    {
      title: "Pendaftaran",
      description:
        "Website sekolah disediakan, termasuk sistem pendaftaran siswa baru yang terintegrasi dengan proses otomatis.",
    },
    {
      title: "Sistem Tagihan",
      description:
        "Manajemen tagihan yang lengkap beserta pembayaran tagihan sekolah melalui akun virtual bank, minimarket, dan QRIS.",
    },
    {
      title: "Kesiswaan",
      description:
        "Informasi umum seperti pengumuman dan informasi terkait prestasi, pelanggaran, kesehatan, perizinan, dan progres.",
    },
    {
      title: "Komunikasi",
      description:
        "Penyebaran informasi bisa menggunakan WhatsApp blast dan notifikasi aplikasi untuk pengingat tagihan dan informasi.",
    },
    {
      title: "Uang Saku Digital",
      description:
        "Uang saku aman yang dapat dimonitor dan dibatasi penggunaannya oleh orang tua, serta fitur top-up dari mana saja.",
    },
    {
      title: "Kantin",
      description:
        "Aplikasi kantin dengan fitur lengkap dapat menerima transaksi digital dari kartu siswa (uang saku digital) dan e-wallet.",
    },
    {
      title: "Toko Online",
      description:
        "Memungkinkan kantin untuk memiliki toko online yang terhubung dengan sistem kantin dan dapat ditampilkan pada aplikasi orang tua.",
    },
  ];
  return (
    <section className="w-full flex justify-center items-center bg-white py-12 lg:my-24 my-6">
      <div className="lg:w-[80%] w-full flex flex-col items-center">
        <header className="w-full flex justify-center">
          <h1 className="text-center lg:text-[44px] leading-tight text-[24px] font-bold lg:w-[90%] w-full">
            Solusi lengkap untuk lembaga pendidikan melalui fitur-fitur{" "}
            <span className="text-primary">CARDS</span>
          </h1>
        </header>

        <div className="flex justify-center items-center flex-wrap">
          {solutionData.map((data, index) => {
            if (index % 2 == 0) {
              return (
                <SolutionItem
                  title={data.title}
                  description={data.description}
                  key={index}
                  variant={"odd"}
                />
              );
            } else {
              return (
                <SolutionItem
                  title={data.title}
                  description={data.description}
                  key={index}
                  variant={"even"}
                />
              );
            }
          })}
        </div>

        <Link
          href={"https://www.jotform.com/240072160338447"}
          target="__blank"
          className="my-8"
        >
          <button className="w-[359px] btn btn-accent rounded-full text-white text-[20px]">
            Fitur lainnya? Jadwalkan Demo
          </button>
        </Link>
      </div>
    </section>
  );
};
