import Image from "next/image";
import Link from "next/link";
import { FaInstagram } from "react-icons/fa6";
import { PiTiktokLogo } from "react-icons/pi";
import { AiOutlineYoutube } from "react-icons/ai";

export default function Footer() {
  return (
    <footer className="footer bg-primary px-4 md:px-24 py-16">
      <aside>
        <Image
          width={144}
          height={500}
          src="/logo-white.png"
          alt="cards logo"
        />
        <p className="text-white text-opacity-60 my-3 text-[16px]">
          Digitize School.
        </p>
        <p className="text-white text-opacity-60 tracking-wider text-[16px]">
          &copy; 2021-{new Date().getFullYear()} CARDS Kartu Digital
        </p>
      </aside>
      <nav>
        <p className="footer-title text-white opacity-100 font-semibold normal-case text-[18px]">
          Links
        </p>
        <a
          href={"/docs/dokumen-proposal (1).pdf"}
          download={true}
          className="link link-hover text-white text-opacity-60 text-[16px]"
        >
          Company Profile
        </a>
        <a
          href={"/docs/dokumen-proposal (1).pdf"}
          download={true}
          className="link link-hover text-white text-opacity-60 text-[16px]"
        >
          Proposal
        </a>
        {/* <Link href={"/tentang/kami"} className="link link-hover text-white text-opacity-60">
          Tentang Kami
        </Link>
        <Link href={"/tentang/mitra-bisnis"} className="link link-hover text-white text-opacity-60">
          Mitra Bisnis
        </Link>
        <Link href={"/tentang/kegiatan"} className="link link-hover text-white text-opacity-60">
          Kegiatan
        </Link> */}
      </nav>
      <nav>
        <p className="footer-title text-white opacity-100 font-semibold normal-case text-[18px]">
          Umum
        </p>
        <Link
          href={"/term-condition"}
          className="link link-hover text-white text-opacity-60 text-[16px]"
        >
          Syarat
        </Link>
        <Link
          href={"/privacy-policy"}
          className="link link-hover text-white text-opacity-60 text-[16px]"
        >
          Kebijakan Privasi
        </Link>
        <a
          href="https://www.jotform.com/240072160338447"
          target="_blank"
          rel="noopener noreferrer"
          className="link link-hover text-white text-opacity-60 text-[16px]"
        >
          Pendaftaran Partner
        </a>
        <Link
          href={"/kontak"}
          className="link link-hover text-white text-opacity-60 text-[16px]"
        >
          Kontak
        </Link>
      </nav>
      <nav>
        <p className="footer-title text-white opacity-100 font-semibold normal-case text-[18px]">
          Selalu Update
        </p>
        <p className="!no-underline cursor-auto link link-hover text-white text-opacity-60 text-[16px]">
          Temukan kami di sosial
          <br />
          media berikut:
        </p>
        <div className="flex gap-2">
          <Link
            aria-label="Cards Instagram"
            href={"https://www.instagram.com/cazhcards"}
            target="_blank"
            className="w-10 h-10 bg-tprimary-3 rounded text-white/80 hover:text-white flex justify-center items-center"
          >
            <FaInstagram className="w-5 h-5" />
          </Link>
          <Link
            aria-label="Cards Tiktok"
            href={"https://www.tiktok.com/@cazhcards?_t=8nb3WdNxJdI&_r=1"}
            target="_blank"
            className="w-10 h-10 bg-tprimary-3 rounded text-white/80 hover:text-white flex justify-center items-center"
          >
            <PiTiktokLogo className="w-5 h-5" />
          </Link>
          <Link
            aria-label="Cards Youtube"
            href={"https://www.youtube.com/@cazhcards"}
            target="_blank"
            className="w-10 h-10 bg-tprimary-3 rounded text-white/80 hover:text-white flex justify-center items-center"
          >
            <AiOutlineYoutube className="w-5 h-5" />
          </Link>
        </div>
      </nav>
    </footer>
  );
}
