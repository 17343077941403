import { FaWhatsapp } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import Link from "next/link";
import { useState } from "react";

const SalesPerson = ({ name, area, image, whatsapp }) => {
  return (
    <Link
      href={`https://api.whatsapp.com/send/?phone=${whatsapp}&text&type=phone_number&app_absent=0`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="flex items-center gap-4 rounded-lg p-2 transition">
        <div className="3xl:w-16 w-12 3xl:h-16 h-12 rounded-full bg-[#00718F] relative overflow-hidden">
          <img
            src={image}
            alt={name}
            className={`w-full h-full object-contain absolute`}
          />
        </div>
        <div>
          <h4 className="text-[16px] font-bold">{name}</h4>
          <p className="text-[16px]">{area}</p>
        </div>
      </div>
    </Link>
  );
};

function ContactSales() {
  const [openModal, setOpenModal] = useState(false);
  const [animate, setAnimate] = useState({
    modal: "animate-slideUp",
    overlay: "animate-fadeIn",
  });
  const salesData = [
    {
      name: "Andi (Sales 1)",
      area: "Semua Area",
      image: "/image/sales/andi.png",
      whatsapp: "6285624140080",
    },
    {
      name: "Meta (Sales 2)",
      area: "Area Jawa Barat - DKI Jakarta",
      image: "/image/sales/meta.png",
      whatsapp: "6285225499059",
    },
    {
      name: "Wida (Sales 3)",
      area: "Area Jawa Tengah - DI Yogyakarta",
      image: "/image/sales/wida.png",
      whatsapp: "62895386438600",
    },
    {
      name: "Wulan (Sales 4)",
      area: "Area Jawa Timur - Indonesia Timur",
      image: "/image/sales/wulan.png",
      whatsapp: "6288215322822",
    },
    {
      name: "Prihtin (Sales 5)",
      area: "Area Kalimantan",
      image: "/image/sales/prihtin.png",
      whatsapp: "6281992443749",
    },
  ];
  return (
    <div>
      <div
        className={`w-full lg:h-screen ${animate.overlay} ${
          openModal ? "bg-black/50 block" : "hidden"
        } fixed top-0 h-[5000px] left-0 z-[60]`}
      >
        {openModal && (
          <div
            className={`fixed lg:bottom-0 bottom-12 lg:right-10 right-2 p-4 z-50`}
          >
            <div
              className={`${animate.modal} p-0 rounded-xl overflow-visible absolute lg:w-[400px] w-[350px] bottom-24 right-0 bg-white`}
            >
              <button
                onClick={() => {
                  setTimeout(() => {
                    setOpenModal(false);
                  }, 450);
                  setAnimate({
                    modal: "animate-slideDown",
                    overlay: "animate-fadeOut",
                  });
                }}
                className="h-10 w-10 rounded-full bg-[#00718F] absolute right-0 3xl:-top-16 -top-14 text-white border-none"
              >
                <p className="flex justify-center items-center">
                  <RxCross2 className="w-6 h-6 font-bold" />
                </p>
              </button>

              <div className="bg-[#00718F] 3xl:p-4 p-2 rounded-t-xl text-white">
                <h3 className="text-center lg:text-[16px] text-[14px]">
                  Tim sales kami ada di sini untuk menjawab pertanyaan Anda.
                  Silakan tanyakan apa saja kepada kami!
                </h3>
              </div>
              <div className="3xl:p-4 p-2 flex justify-center 3xl:gap-2 gap-1 flex-col">
                {salesData.map((sales, index) => (
                  <SalesPerson
                    name={sales.name}
                    area={sales.area}
                    image={sales.image}
                    whatsapp={sales.whatsapp}
                    key={index}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="fixed lg:bottom-2 bottom-16 lg:right-20 right-0 p-4 z-[70]">
        <button
          name="contact-sales"
          onClick={() => {
            setOpenModal(true);
            setAnimate({ modal: "animate-slideUp", overlay: "animate-fadeIn" });
          }}
          className="bg-[#25D366] lg:w-[325px] lg:aspect-auto aspect-square text-white py-2 px-4 rounded-full"
        >
          <p className="flex justify-center items-center gap-2 text-[20px] cursor-pointer">
            <FaWhatsapp name="contact-button" className="w-6 h-6" />
            <span className="lg:block hidden">Hubungi Tim Sales Kami</span>
          </p>
        </button>
      </div>
    </div>
  );
}

export default ContactSales;
