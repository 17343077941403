"use client";

import CallToAction from "@/components/CallToAction";
import DrawerWrapper from "@/components/DrawerWrapper";
import Footer from "@/components/Footer";
import Navbar from "@/components/Navbar";
import DetailedFeatures from "@/components/Homepage/DetailedFeatures";
import FAQ from "@/components/Homepage/FAQ";
import Features from "@/components/Homepage/Features";
import Hero from "@/components/Homepage/Hero";
import Testimonial from "@/components/Homepage/Testimonial";
import { Solution } from "@/components/Homepage/Solution";
import ContactSales from "@/components/ContactSales";
import Articles from "@/components/Homepage/Articles";

export default function Home() {
  return (
    <DrawerWrapper>
      <Navbar />
      <Hero />
      <Features />
      <Solution />
      <DetailedFeatures />
      <Testimonial />
      <Articles />
      <FAQ />
      <CallToAction backgroundImage={true} />
      <Footer />
      <ContactSales />
    </DrawerWrapper>
  );
}
