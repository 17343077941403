import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, FreeMode } from "swiper/modules";
import getTestimonials from "@/lib/GetTestimonials";
import SectionMark from "../Misc/SectionMark";
import Image from "next/image";
import Link from "next/link";

export default function Testimonial() {
  let testi = getTestimonials(0);
  let testi2 = getTestimonials(1);

  return (
    <section className="relative">
      <div className="bg-[#026079] mt-20 pb-36 ">
        <div className="grid grid-cols-1 md:grid-cols-2 mx-auto w-[85%] px-2 pt-16">
          <div>
            <SectionMark variant="white" className={"mb-5"}>
              <p className="lg:text-[20px] text-[16px] uppercase">Testimoni</p>
            </SectionMark>
            <h1 className="lg:text-[32px] text-[24px] font-bold tracking-wide text-white">
              Apa Kata Mereka?
            </h1>
            <p className="lg:my-2 my-4 text-white/80 tracking-tight lg:text-[20px] text-[14px]">
              Kami sudah dipercaya sejak 2019, dan sudah memiliki banyak mitra
              lembaga pendidikan di seluruh Indonesia dari Sabang sampai
              Merauke.
            </p>
            <Link
              href="https://www.youtube.com/@cazhcards"
              target="_blank"
              className="btn lg:bg-tprimary-4 lg:border-tprimary-4 lg:hover:bg-tprimary-4/90 lg:hover:border-tprimary-4/90 bg-[#FC7F3A] border-[#FC7F3A] hover:bg-[#FC7F3A]/90 hover:border-[#FC7F3A]/90 text-white rounded-full w-[229px] !font-semibold text-[20px]"
            >
              Testimoni Lainnya
            </Link>
          </div>
        </div>
        <div className="lg:my-16 my-8">
          <Swiper
            slidesPerView={1}
            grabCursor={true}
            spaceBetween={30}
            freeMode={true}
            loop={true}
            autoplay={{ delay: 0 }}
            speed={18000}
            direction="horizontal"
            breakpoints={{
              640: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
            modules={[FreeMode, Autoplay]}
            className="mySwiper"
          >
            {testi.map((x, idx) => {
              return (
                <SwiperSlide
                  key={idx}
                  className="bg-white p-6 2xl:min-h-56 lg:h-auto rounded-3xl text-dark-muted/80"
                >
                  <p className="leading-[25px] text-[16px] tracking-tight text-opacity-80">
                    {x.description}
                  </p>
                  <div className="flex gap-2 2xl:mt-4 mt-8">
                    <div className="avatar">
                      <div className="w-10 rounded-full">
                        <Image
                          width={500}
                          height={500}
                          src={x.avatar}
                          alt="avatar image"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <span className="font-semibold tracking-tight text-opacity-80 text-[16px]">
                        {x.name}
                      </span>
                      <span className="tracking-tight text-opacity-80 text-[16px]">
                        {x.school}
                      </span>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <Swiper
            slidesPerView={1}
            grabCursor={true}
            spaceBetween={30}
            freeMode={true}
            loop={true}
            autoplay={{ delay: 0, reverseDirection: true }}
            speed={18000}
            direction="horizontal"
            breakpoints={{
              640: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
            modules={[FreeMode, Autoplay]}
            className="mt-4"
          >
            {testi2.map((x, idx) => {
              return (
                <SwiperSlide
                  key={idx}
                  className="bg-white p-6 2xl:min-h-56 lg:h-auto rounded-3xl text-dark-muted/80"
                >
                  <p className="leading-[25px] tracking-tight text-opacity-80">
                    {x.description}
                  </p>
                  <div className="flex gap-2 2xl:mt-4 mt-8">
                    <div className="avatar">
                      <div className="w-10 rounded-full">
                        <Image
                          width={500}
                          height={500}
                          src={x.avatar}
                          alt="avatar image"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <span className="font-semibold tracking-tight text-opacity-80 text-[16px]">
                        {x.name}
                      </span>
                      <span className="tracking-tight text-opacity-80 text-[16px]">
                        {x.school}
                      </span>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>

      {/* <div className="absolute right-1/2 left-1/2 transform -translate-x-1/2 -translate-y-4 -bottom-36 w-[100%] swiper-yt">
                    <div className="cursor-pointer swiper-button hidden lg:block absolute top-[50%] right-[30%] z-[999] text-tprimary-4 image-swiper-button-next">
                        <FiArrowRightCircle className='w-7 h-7' />
                    </div>
                    <div className="cursor-pointer swiper-button hidden lg:block absolute top-[50%] left-[30%] z-[999] text-tprimary-4 image-swiper-button-prev">
                        <FiArrowLeftCircle className='w-7 h-7' />
                    </div>
                    <Swiper
                        slidesPerView={3}
                        grabCursor={true}
                        initialSlide={1}
                        spaceBetween={30}
                        watchSlidesProgress={true}
                        centeredSlides={true}
                        navigation={{
                            nextEl: ".image-swiper-button-next",
                            prevEl: ".image-swiper-button-prev",
                            disabledClass: "text-dark-muted/60"
                        }}
                        breakpoints={{
                            362: {
                              slidesPerView: 1,
                            },
                            529: {
                              slidesPerView: 2,
                            },
                            1024: {
                              slidesPerView: 3,
                            },
                        }}
                        effect={'coverflow'}
                        coverflowEffect={{
                            rotate: 0,
                            stretch: 50,
                            depth: 250,
                            modifier: 0.5,
                            slideShadows: true,
                        }}
                        modules={[EffectCoverflow, Navigation]}
                        className="mt-4"
                    >
                        {yttesti.map((x, idx) => {
                            return <SwiperSlide key={idx}>
                                {({ isActive }) => {
                                    return <a href={x.link} target='_blank'><Image width={500} height={500} src={x.thumbnail} alt="avatar image" className={`${isActive ? '' : 'opacity-50'} rounded-3xl`} /></a>
                                }}
                            </SwiperSlide>
                        })}
                    </Swiper>
                    <div className="flex justify-center mt-4 gap-4 lg:hidden">
                        <div className="cursor-pointer swiper-button z-[999] text-tprimary-4 image-swiper-button-prev">
                            <FiArrowLeftCircle className='w-7 h-7' />
                        </div>
                        <div className="cursor-pointer swiper-button z-[999] text-tprimary-4 image-swiper-button-next">
                            <FiArrowRightCircle className='w-7 h-7' />
                        </div>
                    </div>
           </div> */}
    </section>
  );
}
